import React, { useState } from 'react';
import { ThumbsUp, ThumbsDown } from 'lucide-react';
import { Button } from './ui/button';

const QuestionFeedback = ({ questionId }) => {
  const [feedback, setFeedback] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFeedback = async (value) => {
    if (feedback === value) {
      setFeedback(null);
      return;
    }
    
    setIsSubmitting(true);
    setFeedback(value);
    
    // Simulate API call - replace with actual Firebase implementation later
    await new Promise(resolve => setTimeout(resolve, 300));
    setIsSubmitting(false);
  };

  return (
    <div className="flex items-center space-x-2">
      <Button
        variant="ghost"
        size="sm"
        className={`p-2 hover:bg-gray-100 transition-colors ${
          feedback === 'up' ? 'text-green-600' : 'text-gray-500'
        }`}
        onClick={() => handleFeedback('up')}
        disabled={isSubmitting}
      >
        <ThumbsUp className={`h-5 w-5 ${feedback === 'up' ? 'fill-current' : ''}`} />
      </Button>
      <Button
        variant="ghost"
        size="sm"
        className={`p-2 hover:bg-gray-100 transition-colors ${
          feedback === 'down' ? 'text-red-600' : 'text-gray-500'
        }`}
        onClick={() => handleFeedback('down')}
        disabled={isSubmitting}
      >
        <ThumbsDown className={`h-5 w-5 ${feedback === 'down' ? 'fill-current' : ''}`} />
      </Button>
    </div>
  );
};

export default QuestionFeedback;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { motion } from 'framer-motion';
import { CheckCircle, XCircle, AlertCircle } from 'lucide-react';

const Checkout = () => {
  const { user, loading, refreshUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState('processing');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!loading) {
      if (!user) {
        navigate('/login', { 
          state: { 
            from: '/checkout',
            intent: 'checkout'
          } 
        });
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');

        if (sessionId) {
          checkStripeSession(sessionId);
        } else {
          navigate('/pricing');
        }
      }
    }
  }, [user, loading, navigate]);

  const checkStripeSession = async (sessionId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_STRIPE_BASE_URL}/api/check-session-status?session_id=${sessionId}`);
      if (!response.ok) {
        throw new Error('Failed to check session status');
      }
      const data = await response.json();

      if (data.status === 'complete') {
        setStatus('success');
        await refreshUserData();
      } else if (data.status === 'failed') {
        setStatus('failed');
        setError('Payment failed. Please try again.');
      } else {
        setStatus('processing');
      }
    } catch (error) {
      console.error('Error checking Stripe session:', error);
      setStatus('failed');
      setError('An error occurred while processing your payment. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex justify-center items-center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-2xl font-bold text-gray-800"
        >
          Loading...
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-8 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6">
            <CardTitle className="text-2xl font-bold text-center">Checkout Status</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            {status === 'processing' && (
              <div className="text-center">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                  className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full mx-auto mb-4"
                />
                <p className="text-lg font-semibold">Processing your payment...</p>
              </div>
            )}
            {status === 'success' && (
              <div className="text-center">
                <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
                <p className="text-lg font-semibold mb-4">Payment Successful!</p>
                <p className="text-gray-600 mb-6">Thank you for your purchase. Your account has been upgraded.</p>
                <Button 
                  onClick={() => navigate('/account')}
                  className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300"
                >
                  Go to Account
                </Button>
              </div>
            )}
            {status === 'failed' && (
              <div className="text-center">
                <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
                <p className="text-lg font-semibold mb-4">Payment Failed</p>
                {error && <p className="text-red-500 mb-6">{error}</p>}
                <Button 
                  onClick={() => navigate('/pricing')}
                  className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300"
                >
                  Try Again
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default Checkout;
import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { 
  BarChart, Bar, Cell,
  ResponsiveContainer, XAxis, YAxis, Tooltip,
  RadarChart, PolarGrid, PolarAngleAxis,
  PolarRadiusAxis, Radar
} from 'recharts';

const COLORS = {
  primary: ['#4F46E5', '#6366F1', '#818CF8', '#93C5FD', '#60A5FA'],
  difficulty: ['#3B82F6', '#EC4899']
};

const ExamAnalytics = ({ exam, attemptId }) => {
  const analytics = useMemo(() => {
    if (!exam?.content?.questions || !exam.attempts) {
      return null;
    }

    const attempt = exam.attempts.find(a => a.id === attemptId);
    if (!attempt?.answers) return null;

    // Initialize analytics structures
    const primaryDomainAnalytics = {};

    // Process each question
    exam.content.questions.forEach((question, index) => {
      const userAnswer = attempt.answers[index] || [];
      const userAnswerArray = Array.isArray(userAnswer) ? userAnswer : [userAnswer];
      const correctAnswerArray = Array.isArray(question.answers) ? question.answers : [question.answers];
      
      // Compare answers
      const isCorrect = JSON.stringify(userAnswerArray.sort()) === JSON.stringify(correctAnswerArray.sort());
      
      // Get domain from metadata structure
      const domain = question.metadata?.domain || 'Uncategorized';
      if (!primaryDomainAnalytics[domain]) {
        primaryDomainAnalytics[domain] = { correct: 0, total: 0 };
      }
      primaryDomainAnalytics[domain].total++;
      if (isCorrect) primaryDomainAnalytics[domain].correct++;
    });

    // Format data for charts
    const primaryDomainData = Object.entries(primaryDomainAnalytics)
      .map(([domain, stats]) => ({
        name: domain,
        score: Number((stats.correct / stats.total * 100).toFixed(1)),
        correct: stats.correct,
        total: stats.total
      }))
      .sort((a, b) => b.score - a.score);

    // Calculate overall score
    const totalCorrect = Object.values(primaryDomainAnalytics).reduce((sum, { correct }) => sum + correct, 0);
    const totalQuestions = exam.content.questions.length;
    const overallScore = totalQuestions > 0 ? Number(((totalCorrect / totalQuestions) * 100).toFixed(1)) : 0;

    return {
      primaryDomainData,
      overallScore
    };
  }, [exam, attemptId]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload?.[0]) return null;
    const data = payload[0].payload;
    return (
      <div className="bg-white p-3 shadow-lg rounded-lg border">
        <p className="font-bold">{label}</p>
        <p>Score: {data.score}%</p>
        <p>Correct: {data.correct}/{data.total}</p>
      </div>
    );
  };

  if (!analytics || analytics.primaryDomainData.length === 0) return null;

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <span>Domain Performance Analysis</span>
            <span className="text-lg text-red-600">
              Overall Score: {analytics.overallScore}%
            </span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={analytics.primaryDomainData}>
                  <XAxis 
                    dataKey="name" 
                    angle={-45} 
                    textAnchor="end" 
                    height={100}
                    interval={0}
                  />
                  <YAxis 
                    domain={[0, 100]}
                    label={{ 
                      value: 'Score (%)', 
                      angle: -90, 
                      position: 'insideLeft',
                      offset: 10
                    }} 
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="score">
                    {analytics.primaryDomainData.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`}
                        fill={entry.score >= 70 ? '#22C55E' : '#EF4444'}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart data={analytics.primaryDomainData} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                  <PolarGrid 
                    gridType="polygon"
                    radialLines={false}
                  />
                  <PolarAngleAxis 
                    dataKey="name" 
                    tick={{ fill: '#6B7280', fontSize: 12 }}
                    tickLine={false}
                  />
                  <PolarRadiusAxis 
                    tick={false}
                    tickCount={6}
                    domain={[0, 100]}
                    axisLine={false}
                    label={false}
                  />
                  <Radar 
                    name="Score" 
                    dataKey="score" 
                    stroke="#4F46E5" 
                    fill="#4F46E5" 
                    fillOpacity={0.6} 
                  />
                  <Tooltip content={<CustomTooltip />} />
                </RadarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};


export default ExamAnalytics;
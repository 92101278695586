import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Check, LogIn } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { UserContext } from './UserContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const LoginPrompt = ({ onLogin }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
        <CardContent className="p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Login Required</h2>
          <p className="text-gray-600 mb-6">Please log in to purchase lifetime access.</p>
          <Button
            onClick={onLogin}
            className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
          >
            <LogIn className="mr-2 h-5 w-5" />
            Log In
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const Pricing = () => {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const navigate = useNavigate();
  const { user, loginWithGoogle } = useContext(UserContext);

  const createStripeCheckoutSession = async () => {
    try {
      if (!user) {
        setShowLoginPrompt(true);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_STRIPE_BASE_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          planId: 'lifetime',
          userId: user.id,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }

      const session = await response.json();

      if (session.id) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id
        });

        if (error) {
          console.error('Stripe redirect error:', error);
          alert(`An error occurred: ${error.message}`);
        }
      }
    } catch (error) {
      console.error('Error creating Stripe Checkout session:', error);
      alert('Failed to start checkout process. Please try again.');
    }
  };

  const handleLogin = async () => {
    try {
      await loginWithGoogle();
      setShowLoginPrompt(false);
      await createStripeCheckoutSession();
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please try again.');
    }
  };

  const features = [
    "Lifetime access to AI-powered practice tests",
    "Generate unlimited practice tests",
    "Detailed performance analytics",
    "Email support",
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 pb-16 px-4">
      <div className="max-w-3xl mx-auto space-y-12">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-8"
        >
          <h1 className="text-4xl font-bold text-center text-gray-800">Get Lifetime Access</h1>
          <p className="text-xl text-center text-gray-600">One-time payment, unlimited practice tests forever</p>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <Card className="border-4 border-blue-500 overflow-hidden">
            <CardHeader className="py-8 px-6 bg-gradient-to-r from-blue-500 to-purple-600 text-white">
              <CardTitle className="text-2xl font-bold text-center">Lifetime Access</CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              <p className="text-4xl font-bold text-center mb-2">
                $49.99<span className="text-lg font-normal"> one-time</span>
              </p>
              <p className="text-center text-green-600 font-semibold mb-6">
                No recurring fees!
              </p>
              <ul className="space-y-3 mb-8">
                {features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <Button 
                onClick={createStripeCheckoutSession}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
              >
                Get Started Now
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
            <CardContent className="p-6 text-center">
              <h3 className="text-xl font-bold text-gray-800 mb-2">7-Day Money-Back Guarantee</h3>
              <p className="text-gray-600">
                Try our platform risk-free. If you're not completely satisfied within 7 days, we'll refund your payment. No questions asked!
              </p>
            </CardContent>
          </Card>
        </motion.div>
      </div>
      
      <AnimatePresence>
        {showLoginPrompt && <LoginPrompt onLogin={handleLogin} />}
      </AnimatePresence>
    </div>
  );
};

const WrappedPricing = () => (
  <Elements stripe={stripePromise}>
    <Pricing />
  </Elements>
);

export default WrappedPricing;
export const Alert = ({ children, variant = "default", className = "" }) => {
    const variants = {
      default: "bg-gray-50 border-gray-200 text-gray-700",
      success: "bg-green-50 border-green-200 text-green-700",
      error: "bg-red-50 border-red-200 text-red-700",
    };
  
    return (
      <div className={`p-4 rounded-lg border ${variants[variant]} ${className}`}>
        {children}
      </div>
    );
  };
  
  export const AlertDescription = ({ children, className = "" }) => (
    <div className={`text-sm mt-1 ${className}`}>{children}</div>
  );
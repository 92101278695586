import React, { useState, useContext, useEffect, useCallback } from 'react';
import { UserContext } from './UserContext';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Send, LogIn, AlertCircle, CreditCard, FolderOpen, Loader2 } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { motion, AnimatePresence } from 'framer-motion';
import ExamGenerationProgress from './ExamGenerationProgress';


const API_ENDPOINT = 'https://api.practicequiz.ai';

const LoginPrompt = ({ onLogin }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
        <CardContent className="p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Login Required</h2>
          <p className="text-gray-600 mb-6">Please log in to generate practice exams and start learning.</p>
          <Button
            onClick={onLogin}
            className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
          >
            <LogIn className="mr-2 h-5 w-5" />
            Log In
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const ErrorModal = ({ message, onClose, isExamLimitError, onUpgrade, onManageExams }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
        <CardContent className="p-6">
          <div className="flex items-center mb-4">
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
            <h2 className="text-2xl font-bold text-gray-800">Error</h2>
          </div>
          <p className="text-gray-600 mb-6">{message}</p>
          {isExamLimitError ? (
            <div className="space-y-4">
              <Button
                onClick={onUpgrade}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
              >
                <CreditCard className="mr-2 h-5 w-5" />
                Upgrade Plan
              </Button>
              <Button
                onClick={onManageExams}
                className="w-full bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
              >
                <FolderOpen className="mr-2 h-5 w-5" />
                Manage Existing Exams
              </Button>
            </div>
          ) : (
            <Button
              onClick={onClose}
              className="w-full bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
            >
              Close
            </Button>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

const placeholderPhrases = [
  "Azure Administrator (AZ-104)",
  "AWS Solutions Architect Associate",
  "Python Programming",
  "CompTIA Security+",
  "Google Cloud Engineer",
  "Machine Learning Basics"
];

const useAnimatedPlaceholder = (phrases, typingSpeed = 100, deletingSpeed = 50, pauseDuration = 2000) => {
  const [placeholderText, setPlaceholderText] = useState('');
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let timeout;

    const animate = () => {
      const currentPhrase = phrases[currentPhraseIndex];

      if (isPaused) {
        timeout = setTimeout(() => {
          setIsPaused(false);
          setIsDeleting(true);
        }, pauseDuration);
        return;
      }

      if (isDeleting) {
        if (placeholderText === '') {
          setIsDeleting(false);
          setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
        } else {
          setPlaceholderText(prev => prev.slice(0, -1));
        }
        timeout = setTimeout(animate, deletingSpeed);
      } else {
        if (placeholderText === currentPhrase) {
          setIsPaused(true);
        } else {
          setPlaceholderText(currentPhrase.slice(0, placeholderText.length + 1));
        }
        timeout = setTimeout(animate, typingSpeed);
      }
    };

    timeout = setTimeout(animate, typingSpeed);
    return () => clearTimeout(timeout);
  }, [placeholderText, currentPhraseIndex, isDeleting, isPaused, phrases, typingSpeed, deletingSpeed, pauseDuration]);

  return placeholderText;
};

const LLMInteraction = () => {
  const [input, setInput] = useState(() => {
    const savedPrompt = localStorage.getItem('savedPrompt');
    return savedPrompt || '';
  });
  
  const { user, addExam, updateExamScore } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isExamLimitError, setIsExamLimitError] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [generationStage, setGenerationStage] = useState('ANALYZING');
  const navigate = useNavigate();
  const location = useLocation();

  const animatedPlaceholder = useAnimatedPlaceholder(placeholderPhrases);

  const generateTest = async (prompt) => {
    if (!user) {
      localStorage.setItem('savedPrompt', prompt);
      setShowLoginPrompt(true);
      return;
    }
  
    setIsLoading(true);
    setError(null);
    setIsExamLimitError(false);
    setMetadata(null);
    setGenerationStage('ANALYZING');
  
    try {
      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken(true);
  
      // Step 1: Get metadata and check for existing test
      const metadataResponse = await fetch(`${API_ENDPOINT}/analyze-topic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${idToken}`,
        },
        body: JSON.stringify({ prompt }),
      });
  
      if (!metadataResponse.ok) {
        throw new Error('Failed to analyze topic');
      }
  
      const { metadata: topicMetadata, existingQuestions, isExisting } = await metadataResponse.json();
      setMetadata({ ...topicMetadata, isExisting });
  
      // Wait for the ANALYZING stage animation
      await new Promise(resolve => setTimeout(resolve, isExisting ? 2000 : 3000));
  
      let examForStorage;
  
      if (isExisting) {
        setGenerationStage('PROCESSING');
        await new Promise(resolve => setTimeout(resolve, 2000));
  
        examForStorage = {
          exam: {
            name: topicMetadata.identifier.displayName,
            metadata: topicMetadata,
            content: {
              questions: existingQuestions,
              status: 'complete',
              totalBatches: 3,
              completedBatches: 3,
              totalQuestions: existingQuestions.length
            }
          },
          isNewAttempt: true
        };
  
        setGenerationStage('FINALIZING');
        localStorage.setItem('currentExam', JSON.stringify(examForStorage));
      } else {
        setGenerationStage('GENERATING');
        const testResponse = await fetch(`${API_ENDPOINT}/generate-test`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${idToken}`,
          },
          body: JSON.stringify({ prompt, metadata: topicMetadata }),
        });
  
        if (!testResponse.ok) {
          throw new Error('Failed to generate test');
        }
  
        const { result } = await testResponse.json();
        
        setGenerationStage('PROCESSING');
        await new Promise(resolve => setTimeout(resolve, 5000));
  
        const generatedExam = JSON.parse(result);
        examForStorage = {
          exam: {
            name: generatedExam.metadata?.identifier?.displayName || '',
            metadata: topicMetadata,
            content: {
              questions: generatedExam.questions || [],
              status: 'complete',
              totalBatches: 3,
              completedBatches: 3,
              totalQuestions: generatedExam.questions.length
            }
          },
          isNewAttempt: true
        };
  
        setGenerationStage('FINALIZING');
        localStorage.setItem('currentExam', JSON.stringify(examForStorage));
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    } catch (error) {
      console.error('Error generating test:', error);
      setError(error.message || 'An unknown error occurred. Please try again.');
      setIsLoading(false);
    }
  };
  
  // In your render method:
  {isLoading && (
    <ExamGenerationProgress 
      isLoading={isLoading}
      metadata={metadata}
      stage={generationStage}
      isExisting={metadata?.isExisting}
      onComplete={() => {
        setIsLoading(false);
        navigate('/practice-test');
      }}
    />
  )}

  useEffect(() => {
    const savedPrompt = localStorage.getItem('savedPrompt');
    if (savedPrompt && user) {
      localStorage.removeItem('savedPrompt');
      generateTest(savedPrompt);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      generateTest(input.trim());
    }
  };

  const handleLogin = () => {
    navigate('/login', { 
      state: { 
        previousPath: '/generate'
      }
    });
  };

  const handleCloseError = () => {
    setError(null);
    setIsExamLimitError(false);
  };

  const handleUpgrade = () => {
    navigate('/pricing');
  };

  const handleManageExams = () => {
    navigate('/account');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
      <div className="w-full max-w-4xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            AI Generated Practice Tests
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Examine your skills in any topic or exam with the help of AI
          </p>
          
          <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
            <CardContent className="p-6">
              <form onSubmit={handleSubmit} className="mb-4">
                <div className="flex items-center space-x-2 bg-white rounded-full shadow-inner p-2">
                  <Input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={input ? "" : animatedPlaceholder}
                    className="flex-grow border-none shadow-none focus:ring-0"
                    disabled={isLoading}
                  />
                  <Button 
                    type="submit" 
                    className="bg-transparent hover:bg-gray-100 rounded-full p-2" 
                    disabled={isLoading}
                  >
                    <Send className="h-5 w-5 text-gray-500" />
                  </Button>
                </div>
              </form>
  
              {isLoading && (
                <ExamGenerationProgress 
                  isLoading={isLoading}
                  metadata={metadata}
                  stage={generationStage}
                  onComplete={() => {
                    // Progress is complete, but actual navigation happens in generateTest
                  }}
                />
              )}
            </CardContent>
          </Card>
        </motion.div>
      </div>
  
      <AnimatePresence>
        {showLoginPrompt && <LoginPrompt onLogin={handleLogin} />}
        {error && (
          <ErrorModal 
            message={error} 
            onClose={handleCloseError}
            isExamLimitError={isExamLimitError}
            onUpgrade={handleUpgrade}
            onManageExams={handleManageExams}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default LLMInteraction;
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Trophy, BarChart2, Clock, ArrowRight, FileText, Loader2 } from 'lucide-react';
import { UserContext } from './UserContext';
import ExamAnalytics from './ExamAnalytics';

// Helper function for parsing answers
const parseAnswers = (answers) => {
  try {
    return Array.isArray(answers) ? 
      answers.map(answer => typeof answer === 'string' ? JSON.parse(answer) : answer) : 
      [answers];
  } catch {
    return answers;
  }
};

const ExamResultScreen = () => {
  const navigate = useNavigate();
  const { examId, attemptId } = useParams();
  const { user, updateExamScore } = useContext(UserContext);
  const [examResult, setExamResult] = useState(null);
  const [exam, setExam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExamResult = async () => {
      try {
        setLoading(true);
        setError(null);

        // First try to get data from currentExamResult in localStorage
        const currentExamResult = localStorage.getItem('currentExamResult');
        if (currentExamResult) {
          const { exam: storedExam, attempt: storedAttempt } = JSON.parse(currentExamResult);
          
          // Ensure answers are properly parsed
          const parsedAttempt = {
            ...storedAttempt,
            answers: parseAnswers(storedAttempt.answers),
            questionResponses: storedAttempt.questionResponses.map(qr => ({
              ...qr,
              userAnswers: parseAnswers(qr.userAnswers)
            }))
          };

          setExam(storedExam);
          setExamResult({
            examName: storedExam.name,
            score: parsedAttempt.score,
            totalQuestions: storedExam.content.questions.length,
            correctAnswers: parsedAttempt.questionResponses.filter(r => r.correct).length,
            timeTaken: parsedAttempt.timeTaken,
            attempt: parsedAttempt
          });

          // Clean up the temporary storage
          localStorage.removeItem('currentExamResult');
          setLoading(false);
          return;
        }

        // If no currentExamResult, try to find it in user's savedExams
        if (!user?.savedExams) {
          throw new Error('User data not available');
        }

        const decodedExamId = decodeURIComponent(examId);
        const foundExam = user.savedExams.find(e => e.name === decodedExamId);

        if (!foundExam) {
          throw new Error('Exam not found');
        }

        setExam(foundExam);

        const attempt = foundExam.attempts.find(a => a.id === attemptId);
        if (!attempt) {
          throw new Error('Attempt not found');
        }

        // Ensure answers are properly parsed
        const parsedAttempt = {
          ...attempt,
          answers: parseAnswers(attempt.answers),
          questionResponses: attempt.questionResponses ? attempt.questionResponses.map(qr => ({
            ...qr,
            userAnswers: parseAnswers(qr.userAnswers)
          })) : []
        };

        // Calculate results from the attempt data
        const totalQuestions = foundExam.content.questions.length;
        const correctAnswers = parsedAttempt.questionResponses?.filter(r => r.correct).length || 
                           Math.round(parsedAttempt.score * totalQuestions / 100);

        setExamResult({
          examName: foundExam.name,
          score: parsedAttempt.score,
          totalQuestions,
          correctAnswers,
          timeTaken: parsedAttempt.timeTaken || "N/A",
          attempt: parsedAttempt
        });

      } catch (error) {
        console.error('Error fetching exam result:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExamResult();
  }, [user, examId, attemptId]);

  const handleReturnToAccount = () => {
    navigate('/account');
  };

  const handleRetakeExam = async () => {
    if (exam) {
      try {
        const timestamp = Date.now();
        const newAttempt = {
          id: timestamp.toString(),
          score: null,
          date: new Date(timestamp).toISOString().split('T')[0],
          answers: [],
          completed: false
        };

        // Create new exam object with the new attempt
        const completeExam = {
          ...exam,
          content: {
            ...exam.content,
            questions: exam.content.questions,
            status: 'complete'
          }
        };

        // Store in localStorage WITHOUT adding the attempt to the exam
        localStorage.setItem('currentExam', JSON.stringify(completeExam));

        // Add the attempt through UserContext
        await updateExamScore(exam.name, newAttempt, completeExam);

        navigate('/practice-test');
      } catch (error) {
        console.error("Error starting new attempt:", error);
        setError("Failed to start new attempt");
      }
    }
  };

  const handleReviewAttempt = () => {
    if (exam && examResult?.attempt) {
      localStorage.setItem('reviewExam', JSON.stringify({
        exam: exam,
        attempt: examResult.attempt
      }));
      navigate(`/attempt-review/${encodeURIComponent(examId)}/${attemptId}`);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="h-12 w-12 animate-spin text-blue-500 mx-auto mb-4" />
          <p className="text-gray-600">Loading results...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
        <Card className="w-full max-w-md mx-auto">
          <CardContent className="p-6">
            <p className="text-red-500 mb-4">{error}</p>
            <Button onClick={handleReturnToAccount} className="w-full">
              Return to Account
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!examResult) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
        <div className="text-xl text-gray-600">No results available</div>
      </div>
    );
  }

  const correctAnswersPercentage = examResult.totalQuestions > 0
    ? (examResult.correctAnswers / examResult.totalQuestions) * 100
    : 0;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 px-8 pb-16">
      <div className="max-w-6xl mx-auto space-y-8">
        {/* Results Summary Card */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-3xl overflow-hidden">
            <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6">
              <CardTitle className="text-2xl font-bold flex items-center justify-center">
                <Trophy className="mr-2 h-6 w-6" /> Exam Results
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              <div className="text-center mb-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  {examResult.examName}
                </h2>
                <div className="text-5xl font-bold text-purple-600">
                  {examResult.score.toFixed(1)}%
                </div>
              </div>
              
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="bg-blue-50 p-4 rounded-xl">
                  <div className="flex items-center text-blue-600 mb-1">
                    <BarChart2 className="mr-2 h-5 w-5" />
                    <span className="font-semibold">Total Questions</span>
                  </div>
                  <div className="text-2xl font-bold text-gray-800">
                    {examResult.totalQuestions}
                  </div>
                </div>
                <div className="bg-purple-50 p-4 rounded-xl">
                  <div className="flex items-center text-purple-600 mb-1">
                    <BarChart2 className="mr-2 h-5 w-5" />
                    <span className="font-semibold">Correct Answers</span>
                  </div>
                  <div className="text-2xl font-bold text-gray-800">
                    {examResult.correctAnswers}
                  </div>
                </div>
              </div>

              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
                <motion.div 
                  className="bg-purple-600 h-2.5 rounded-full transition-all duration-300" 
                  initial={{ width: "0%" }}
                  animate={{ width: `${correctAnswersPercentage}%` }}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                />
              </div>

              <div className="bg-green-50 p-4 rounded-xl mb-6">
                <div className="flex items-center text-green-600 mb-1">
                  <Clock className="mr-2 h-5 w-5" />
                  <span className="font-semibold">Time Taken</span>
                </div>
                <div className="text-2xl font-bold text-gray-800">
                  {examResult.timeTaken}
                </div>
              </div>

              <div className="space-y-3">
                <Button 
                  onClick={handleRetakeExam}
                  className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300"
                >
                  Retake Exam <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
                <Button 
                  onClick={handleReviewAttempt}
                  className="w-full bg-gradient-to-r from-green-500 to-teal-600 hover:from-green-600 hover:to-teal-700 text-white transition-all duration-300"
                >
                  Review Attempt <FileText className="ml-2 h-4 w-4" />
                </Button>
                <Button 
                  onClick={handleReturnToAccount}
                  variant="outline"
                  className="w-full border-purple-300 text-purple-600 hover:bg-purple-50 transition-all duration-300"
                >
                  Return to Account
                </Button>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        {/* Analytics Dashboard */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <ExamAnalytics exam={exam} attemptId={attemptId} />
        </motion.div>
      </div>
    </div>
  );
};

export default ExamResultScreen;
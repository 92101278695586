import React, { createContext, useState, useEffect } from 'react';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  signOut, 
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc
} from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';

export const UserContext = createContext();

// Helper functions for data flattening/unflattening
const flattenAnswers = (answers) => {
  return answers.map(answer => 
    Array.isArray(answer) ? JSON.stringify(answer) : answer
  );
};

const unflattenAnswers = (answers) => {
  return answers.map(answer => {
    try {
      return JSON.parse(answer);
    } catch {
      return answer;
    }
  });
};

const flattenAttempt = (attempt) => {
  return {
    ...attempt,
    answers: attempt.answers ? flattenAnswers(attempt.answers) : [],
    questionResponses: attempt.questionResponses ? attempt.questionResponses.map(qr => ({
      ...qr,
      userAnswers: Array.isArray(qr.userAnswers) ? JSON.stringify(qr.userAnswers) : qr.userAnswers
    })) : []
  };
};

const unflattenAttempt = (attempt) => {
  return {
    ...attempt,
    answers: attempt.answers ? unflattenAnswers(attempt.answers) : [],
    questionResponses: attempt.questionResponses ? attempt.questionResponses.map(qr => ({
      ...qr,
      userAnswers: qr.userAnswers ? JSON.parse(qr.userAnswers) : []
    })) : []
  };
};

const flattenExam = (exam) => {
  return {
    ...exam,
    attempts: exam.attempts ? exam.attempts.map(flattenAttempt) : [],
    content: {
      ...exam.content,
      questions: exam.content.questions.map(q => ({
        ...q,
        answers: Array.isArray(q.answers) ? JSON.stringify(q.answers) : q.answers
      }))
    }
  };
};

const unflattenExam = (exam) => {
  return {
    ...exam,
    attempts: exam.attempts ? exam.attempts.map(unflattenAttempt) : [],
    content: {
      ...exam.content,
      questions: exam.content.questions.map(q => ({
        ...q,
        answers: q.answers ? JSON.parse(q.answers) : []
      }))
    }
  };
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        fetchUserData(firebaseUser.uid);
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const data = userDoc.data();
        const maxExams = getMaxExams(data.plan);
        
        // Unflatten saved exams data
        const unflattenedExams = Array.isArray(data.savedExams) 
          ? data.savedExams.map(exam => unflattenExam(exam))
          : [];

        setUser({ 
          id: userDoc.id, 
          ...data,
          savedExams: unflattenedExams,
          plan: data.plan || 'free',
          planStartDate: data.planStartDate || null,
          planEndDate: data.planEndDate || null,
          stripeCustomerId: data.stripeCustomerId || null,
          stripeSubscriptionId: data.stripeSubscriptionId || null,
          maxExams: maxExams,
          remainingExams: maxExams - (data.savedExams ? data.savedExams.length : 0)
        });
      } else {
        const newUser = {
          id: userId,
          email: auth.currentUser.email,
          name: auth.currentUser.displayName,
          emailVerified: auth.currentUser.emailVerified,
          savedExams: [],
          plan: 'free',
          planStartDate: null,
          planEndDate: null,
          stripeCustomerId: null,
          stripeSubscriptionId: null,
          maxExams: 10,
          remainingExams: 10
        };
        await setDoc(doc(db, 'users', userId), newUser);
        setUser(newUser);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getMaxExams = (plan) => {
    switch (plan) {
      case 'pro':
        return 100;
      case 'enterprise':
        return 500;
      default:
        return 10;
    }
  };

  const loginWithEmailPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        throw new Error("Please verify your email address. A new verification email has been sent.");
      }

      return user;
    } catch (error) {
      console.error("Error during email/password login:", error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (error) {
      console.error("Error during Google login:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  };

  const updateExamScore = async (examName, attempt, completeExam = null) => {
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Validate attempt has required data
    if (!attempt.id || !attempt.completed || attempt.score === null || attempt.score === undefined) {
      console.error('Invalid attempt data:', attempt);
      throw new Error('Invalid attempt data');
    }
  
    try {
      const userRef = doc(db, 'users', user.id);
      const userDoc = await getDoc(userRef);
      const currentSavedExams = userDoc.data()?.savedExams || [];
  
      // Find existing exam
      const examIndex = currentSavedExams.findIndex(e => e.name === examName);
      let updatedExams = [];
  
      if (examIndex >= 0) {
        // Update existing exam
        updatedExams = currentSavedExams.map(exam => {
          if (exam.name === examName) {
            // Get existing attempts, filtering out any incomplete ones
            const existingAttempts = (exam.attempts || [])
              .filter(a => a.completed && a.score !== null && a.score !== undefined);
            
            // Check if this attempt already exists
            const attemptExists = existingAttempts.some(a => a.id === attempt.id);
            
            if (!attemptExists) {
              const newExam = {
                ...(completeExam || exam),
                attempts: [...existingAttempts, attempt],
                lastScore: attempt.score,
                date: new Date().toISOString()
              };
              return flattenExam(newExam);
            }
            return exam;
          }
          return exam;
        });
      } else if (completeExam) {
        // Add new exam
        const newExam = {
          ...completeExam,
          attempts: [attempt],
          lastScore: attempt.score,
          date: new Date().toISOString()
        };
        updatedExams = [...currentSavedExams, flattenExam(newExam)];
      }
  
      // Update Firestore
      await updateDoc(userRef, {
        savedExams: updatedExams
      });
  
      // Update local state
      const unflattenedExams = updatedExams.map(unflattenExam);
      setUser(prevUser => ({
        ...prevUser,
        savedExams: unflattenedExams
      }));
  
      return true;
    } catch (error) {
      console.error("Error updating exam score:", error);
      throw error;
    }
  };

  const signup = async (email, password, name) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: name });
      await sendEmailVerification(user);

      const newUserData = {
        email: email,
        name: name,
        emailVerified: false,
        savedExams: [],
        plan: 'free',
        planStartDate: null,
        planEndDate: null,
        stripeCustomerId: null,
        stripeSubscriptionId: null,
        maxExams: 10,
        remainingExams: 10
      };

      await setDoc(doc(db, 'users', user.uid), newUserData);
      setUser({ id: user.uid, ...newUserData });

      return user;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  };

  const forgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  };

  const resetPassword = async (oobCode, newPassword) => {
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      return true;
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  };

  const deleteExam = async (examToDelete) => {
    if (!user || !examToDelete || !examToDelete.name) {
      throw new Error('Invalid delete operation');
    }
  
    try {
      const userRef = doc(db, 'users', user.id);
      
      // Get fresh user data
      const userDoc = await getDoc(userRef);
      const currentData = userDoc.data();
      
      if (!currentData || !Array.isArray(currentData.savedExams)) {
        throw new Error('No saved exams found');
      }
  
      // Filter out the exam to delete
      const updatedExams = currentData.savedExams.filter(exam => 
        exam.name !== examToDelete.name
      );
  
      // Update Firestore with the filtered exams
      await updateDoc(userRef, {
        savedExams: updatedExams,
        // Update remaining exams count if you're tracking it
        remainingExams: getMaxExams(currentData.plan) - updatedExams.length
      });
  
      // Update local state
      setUser(prevUser => ({
        ...prevUser,
        savedExams: updatedExams.map(unflattenExam),
        remainingExams: prevUser.maxExams - updatedExams.length
      }));
  
      // Clean up any related localStorage items
      const storedExam = localStorage.getItem('currentExam');
      if (storedExam) {
        const { exam } = JSON.parse(storedExam);
        if (exam.name === examToDelete.name) {
          localStorage.removeItem('currentExam');
        }
      }
  
      const reviewExam = localStorage.getItem('reviewExam');
      if (reviewExam) {
        const { exam } = JSON.parse(reviewExam);
        if (exam.name === examToDelete.name) {
          localStorage.removeItem('reviewExam');
        }
      }
  
      return true;
    } catch (error) {
      console.error("Error deleting exam:", error);
      throw new Error('Failed to delete exam: ' + error.message);
    }
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      loginWithEmailPassword,
      loginWithGoogle,
      logout, 
      signup,
      forgotPassword,
      resetPassword,
      updateExamScore,
      deleteExam,
      loading 
    }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
import React from 'react';
import { motion } from 'framer-motion';
import { Trophy, Target, Brain } from 'lucide-react';

const StatsOverview = ({ stats }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-3xl blur-3xl -z-10" />
      <div className="grid grid-cols-3 gap-8">
        {[
          {
            icon: <Trophy className="h-8 w-8 text-blue-500" />,
            value: `${stats?.averageScore?.toFixed(1)}%`,
            label: "Average Score",
            gradient: "from-blue-600 to-purple-600"
          },
          {
            icon: <Target className="h-8 w-8 text-purple-500" />,
            value: stats?.totalAttempts,
            label: "Tests Completed",
            gradient: "from-purple-600 to-pink-600"
          },
          {
            icon: <Brain className="h-8 w-8 text-indigo-500" />,
            value: stats?.totalExams,
            label: "Unique Exams",
            gradient: "from-indigo-600 to-blue-600"
          }
        ].map((stat, index) => (
          <motion.div
            key={index}
            className="bg-white/60 backdrop-blur-xl rounded-3xl p-8 flex flex-col items-center"
            whileHover={{ y: -4 }}
            transition={{ duration: 0.2 }}
          >
            {stat.icon}
            <p className={`text-5xl font-bold bg-gradient-to-r ${stat.gradient} bg-clip-text text-transparent mt-2`}>
              {stat.value}
            </p>
            <p className="text-gray-600 text-lg mt-1">{stat.label}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default StatsOverview;
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Brain, 
  Target, 
  Trophy,
  Clock,
  Zap,
  Shield,
  Bot,
  CheckCircle,
  GraduationCap,
  Award,
  ArrowRight
} from 'lucide-react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';

const HomePage = () => {
    const navigate = useNavigate();
  
    const handleStartPracticing = () => {
      navigate('/generate');
    };
  
    return (
      <div className="pt-32 pb-16">
        {/* Hero Section */}
        <section className="px-4 mb-20">
          <div className="max-w-6xl mx-auto text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                Master Any Exam with 
                <br />
                AI-Powered Practice Tests
              </h1>
              <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
                Generate personalized practice tests powered by advanced AI. Perfect for certification prep, academic studies, and professional development.
              </p>
              <Button 
                onClick={handleStartPracticing}
                className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white px-8 py-6 rounded-full text-lg font-semibold"
              >
                Start Practicing Now
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </motion.div>
          </div>
        </section>
  
        {/* Features Grid */}
        <section className="px-4 mb-20">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Why Choose Our Platform</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: <Bot className="h-8 w-8 text-blue-500" />,
                  title: "AI-Generated Questions",
                  description: "Unique questions tailored to your topic, generated by advanced AI models"
                },
                {
                  icon: <Target className="h-8 w-8 text-purple-500" />,
                  title: "Adaptive Learning",
                  description: "Tests adapt to your performance, focusing on areas that need improvement"
                },
                {
                  icon: <Shield className="h-8 w-8 text-green-500" />,
                  title: "Exam-Like Environment",
                  description: "Practice in conditions that mirror real certification exams"
                }
              ].map((feature, index) => (
                <Card key={index} className="bg-white/90 backdrop-blur-md hover:shadow-lg transition-all duration-300">
                  <CardContent className="p-6 text-center">
                    <div className="mb-4 flex justify-center">{feature.icon}</div>
                    <h3 className="text-xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>
  
        {/* Stats Section */}
        <section className="px-4 mb-20 bg-gradient-to-r from-blue-500 to-purple-600 py-16">
          <div className="max-w-6xl mx-auto">
            <div className="grid md:grid-cols-4 gap-8">
              {[
                { number: "50K+", label: "Practice Questions", icon: <Brain className="h-6 w-6" /> },
                { number: "98%", label: "Pass Rate", icon: <Trophy className="h-6 w-6" /> },
                { number: "24/7", label: "AI Availability", icon: <Clock className="h-6 w-6" /> },
                { number: "10K+", label: "Happy Users", icon: <GraduationCap className="h-6 w-6" /> }
              ].map((stat, index) => (
                <div key={index} className="text-center text-white">
                  <div className="flex justify-center mb-2">{stat.icon}</div>
                  <div className="text-4xl font-bold mb-1">{stat.number}</div>
                  <div className="text-sm opacity-90">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
  
        {/* Testimonials */}
        <section className="px-4 mb-20">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">What Our Users Say</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  quote: "The AI-generated questions helped me pass my Azure certification on the first try!",
                  author: "Sarah J.",
                  title: "Azure Solutions Architect"
                },
                {
                  quote: "The most efficient way to prepare for technical certifications. Saved me hours of study time.",
                  author: "Michael R.",
                  title: "Cloud Engineer"
                },
                {
                  quote: "Questions are incredibly relevant and match the actual exam format perfectly.",
                  author: "David M.",
                  title: "DevOps Engineer"
                }
              ].map((testimonial, index) => (
                <Card key={index} className="bg-white/90 backdrop-blur-md">
                  <CardContent className="p-6">
                    <div className="mb-4 text-blue-500">
                      <Award className="h-6 w-6" />
                    </div>
                    <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
                    <div>
                      <div className="font-semibold text-gray-800">{testimonial.author}</div>
                      <div className="text-sm text-gray-500">{testimonial.title}</div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>
  
        {/* CTA Section */}
        <section className="px-4">
          <Card className="max-w-4xl mx-auto bg-gradient-to-r from-blue-500 to-purple-600 text-white">
            <CardContent className="p-12 text-center">
              <h2 className="text-3xl font-bold mb-4">Ready to Start Your Journey?</h2>
              <p className="text-lg mb-8 opacity-90">Join thousands of successful test-takers using our AI-powered platform</p>
              <Button 
                onClick={handleStartPracticing}
                className="bg-white text-blue-600 hover:bg-gray-100 px-8 py-6 rounded-full text-lg font-semibold"
              >
                Get Started For Free
                <Zap className="ml-2 h-5 w-5" />
              </Button>
            </CardContent>
          </Card>
        </section>
      </div>
    );
  };
  
export default HomePage;
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { ExternalLink, CheckCircle, XCircle } from 'lucide-react';

const AttemptReview = () => {
  const navigate = useNavigate();
  const { examId, attemptId } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [attempt, setAttempt] = useState(null);
  const [examContent, setExamContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchAttemptDetails = async () => {
      setIsLoading(true);
      setError(null);
      if (user && user.savedExams) {
        const exam = user.savedExams.find(exam => exam.name === decodeURIComponent(examId));
        if (exam) {
          const foundAttempt = exam.attempts.find(att => att.id === attemptId);
          if (foundAttempt) {
            setAttempt(foundAttempt);
            setExamContent(exam.content);
          } else {
            setError("Attempt not found");
          }
        } else {
          setError("Exam not found");
        }
      } else {
        setError("User data not available");
      }
      setIsLoading(false);
    };
  
    fetchAttemptDetails();
  }, [user, examId, attemptId]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-4 flex items-center justify-center">
        <Card className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg">
          <CardContent className="p-4">
            <p className="text-gray-600">Loading attempt details...</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error || !attempt || !examContent) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-4 flex items-center justify-center">
        <Card className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg">
          <CardContent className="p-4">
            <p className="text-red-500">{error || "Failed to load attempt details"}</p>
            <Button onClick={() => navigate('/account')} className="mt-4 bg-blue-500 hover:bg-blue-600 text-white">
              Back to Account
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  const currentQuestion = examContent?.questions[currentQuestionIndex];
  const userAnswer = attempt?.answers[currentQuestionIndex] || '';
  const isMultipleChoice = Array.isArray(currentQuestion?.answers) && currentQuestion.answers.length > 1;
  const totalQuestions = examContent?.questions.length || 0;

  const handleNext = () => {
    if (currentQuestionIndex + 1 < totalQuestions) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const isQuestionCorrect = (questionIndex) => {
    const question = examContent?.questions[questionIndex];
    const userAnswer = attempt?.answers[questionIndex] || [];
    
    if (!question || !userAnswer) return false;
    
    // For both single and multiple choice questions
    return JSON.stringify(userAnswer.sort()) === JSON.stringify(question.answers.sort());
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-6 pt-20"> {/* Increased top padding */}
      <div className="max-w-6xl mx-auto mt-10 space-y-6"> {/* Added top margin */}
        {/* Question Overview Bar */}
        <Card className="bg-white/90 backdrop-blur-md">
          <CardHeader>
            <CardTitle className="text-xl font-semibold">Question Overview</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-10 gap-2">
              {examContent?.questions.map((_, index) => {
                const isCorrect = isQuestionCorrect(index);
                const isCurrent = currentQuestionIndex === index;
                
                return (
                  <Button
                    key={index}
                    onClick={() => setCurrentQuestionIndex(index)}
                    className={`relative h-16 p-0 ${
                      isCurrent 
                        ? 'ring-2 ring-blue-500 ring-offset-2' 
                        : ''
                    } ${
                      isCorrect 
                        ? 'bg-gradient-to-br from-green-500 to-emerald-600 hover:from-green-600 hover:to-emerald-700' 
                        : 'bg-gradient-to-br from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700'
                    }`}
                  >
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
                      <span className="text-sm font-bold">{index + 1}</span>
                      {isCorrect ? (
                        <CheckCircle className="h-4 w-4" />
                      ) : (
                        <XCircle className="h-4 w-4" />
                      )}
                    </div>
                  </Button>
                );
              })}
            </div>
            <div className="mt-4 flex justify-between text-sm text-gray-600">
              <div className="flex items-center space-x-2">
                <div className="w-4 h-4 rounded-full bg-gradient-to-br from-green-500 to-emerald-600"></div>
                <span>Correct Answers</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-4 h-4 rounded-full bg-gradient-to-br from-red-500 to-pink-600"></div>
                <span>Incorrect Answers</span>
              </div>
            </div>
          </CardContent>
        </Card>
  
        {/* Question Display */}
        <motion.div
          key={currentQuestionIndex}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="w-full"
        >
          <Card className="bg-white shadow-lg rounded-lg">
            <CardHeader className="flex justify-between items-center">
              <div className="space-y-1">
                <CardTitle className="text-xl font-semibold">
                  Question {currentQuestionIndex + 1} of {totalQuestions}
                </CardTitle>
                <p className="text-sm text-gray-500">
                  {isQuestionCorrect(currentQuestionIndex) ? (
                    <span className="text-green-600 flex items-center">
                      <CheckCircle className="h-4 w-4 mr-1" /> Answered Correctly
                    </span>
                  ) : (
                    <span className="text-red-600 flex items-center">
                      <XCircle className="h-4 w-4 mr-1" /> Answered Incorrectly
                    </span>
                  )}
                </p>
              </div>
              <Button onClick={() => navigate('/account')} variant="outline" className="text-blue-500 hover:bg-blue-50">
                Back to Account
              </Button>
            </CardHeader>
            <CardContent>
              {/* Existing question display code */}
              <p className="text-lg mb-4">{currentQuestion.question}</p>
              <div className="space-y-2 mb-6">
                {currentQuestion.options.map((option, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <label className="flex items-center space-x-2 cursor-pointer w-full p-2 rounded">
                      <input
                        type={isMultipleChoice ? "checkbox" : "radio"}
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={userAnswer.includes(option)}
                        readOnly
                        className={isMultipleChoice ? "form-checkbox h-4 w-4 text-blue-600" : "form-radio h-4 w-4 text-blue-600"}
                      />
                      <span className={`text-md ${
                        userAnswer.includes(option) 
                          ? (currentQuestion.answers.includes(option) ? "text-green-600 font-semibold" : "text-red-600")
                          : (currentQuestion.answers.includes(option) ? "text-green-600 font-semibold" : "")
                      }`}>
                        {option}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
              <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                <p className="font-semibold text-blue-700">Correct answer(s): {currentQuestion.answers.join(', ')}</p>
                {currentQuestion.explanation && (
                  <p className="mt-2 text-sm text-gray-600">Explanation: {currentQuestion.explanation}</p>
                )}
                {currentQuestion.reference && (
                  <a
                    href={currentQuestion.reference}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                  >
                    <ExternalLink size={16} className="mr-1" />
                    Learn more
                  </a>
                )}
              </div>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </div>
  );
};

export default AttemptReview;
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { UserContext } from './UserContext';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Trash2, LogOut, Clock, BookOpen, 
  Sparkles, Award, X
} from 'lucide-react';
import StatsOverview from './StatsOverview';
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction
} from './AlertDialog';

const formatDate = (dateString) => {
  if (!dateString) return 'Recent';
  
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Recent';
    }
    
    // Format as YYYY-MM-DD
    return date.toISOString().split('T')[0];
  } catch {
    return 'Recent';
  }
};

const Account = () => {
  const { user, logout, deleteExam, updateExamScore } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [examToDelete, setExamToDelete] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    return () => {
      setSelectedTest(null);
      setError(null);
    };
  }, [user, navigate]);

  const handleDeleteClick = (e, exam) => {
    e.stopPropagation(); // Prevent tab selection when clicking delete
    setExamToDelete(exam);
    setShowDeleteDialog(true);
  };
  
  const handleConfirmDelete = async () => {
    if (examToDelete) {
      try {
        await deleteExam(examToDelete);
        setShowDeleteDialog(false);
        setExamToDelete(null);
        // If the deleted exam was selected, clear the selection
        if (selectedTest?.name === examToDelete.name) {
          setSelectedTest(null);
        }
      } catch (error) {
        setError("Failed to delete exam. Please try again.");
      }
    }
  };

  const handleLogout = async (e) => {
    try {
      e.preventDefault();
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to logout. Please try again.');
    }
  };

  const handleRetakeExam = async (exam, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!exam || !exam.name) {
      setError("Invalid exam data");
      return;
    }
  
    setIsLoading(true);
    try {
      // Only store the exam content and start time
      const examForStorage = {
        exam: {
          ...exam,
          content: {
            ...exam.content,
            questions: exam.content?.questions || [],
            totalQuestions: 30,
            status: 'complete',
            completedBatches: 3,
            totalBatches: 3
          }
        },
        startTime: Date.now() // Track when attempt started
      };
  
      // First clean up any existing exam data
      localStorage.removeItem('currentExamResult');
      localStorage.removeItem('reviewExam');
      localStorage.setItem('currentExam', JSON.stringify(examForStorage));
  
      navigate('/practice-test');
    } catch (error) {
      console.error('Retake exam error:', error);
      setError("Failed to start exam. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewAttempt = (exam, attempt, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!exam || !exam.name || !attempt || !attempt.id) {
      setError("Invalid attempt data");
      return;
    }

    localStorage.setItem('reviewExam', JSON.stringify({
      exam: {
        ...exam,
        content: {
          ...exam.content,
          questions: exam.content.questions.map(q => ({
            ...q,
            answers: Array.isArray(q.answers) ? q.answers : [q.answers]
          }))
        }
      },
      attempt: {
        ...attempt,
        answers: attempt.answers.map(answer => 
          Array.isArray(answer) ? answer : [answer]
        )
      }
    }));

    navigate(`/exam-result/${encodeURIComponent(exam.name)}/${attempt.id}`);
  };

  const stats = useMemo(() => {
    if (!user?.savedExams) return null;
    const exams = user.savedExams;
    return {
      totalExams: exams.length,
      averageScore: exams.reduce((acc, exam) => acc + (exam.lastScore || 0), 0) / (exams.length || 1),
      totalAttempts: exams.reduce((acc, exam) => acc + (exam.attempts?.length || 0), 0),
      examList: exams.sort((a, b) => new Date(b.date) - new Date(a.date))
    };
  }, [user?.savedExams]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <p className="text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 pb-16">
      <div className="max-w-7xl mx-auto px-6">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-4">
            Welcome back, {user?.name}!
          </h1>
          <p className="text-xl text-gray-600">Track your progress and review your practice tests</p>
        </div>
  
        {/* Stats Overview */}
        <div className="mb-20">
          <StatsOverview stats={stats} />
        </div>
  
        {/* Tab-like Navigation */}
        <div className="mb-8">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              Your Practice Tests
            </h2>
            <Button 
              onClick={() => navigate('/')}
              className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white rounded-full px-8 py-6 text-lg flex items-center"
            >
              <BookOpen className="h-5 w-5 mr-2" />
              Create New Test
            </Button>
          </div>
  
          {/* Test Tabs with improved scrolling */}
          <div className="relative">
            <div className="flex items-center space-x-2 mb-4 overflow-x-auto pb-4 custom-scrollbar -mx-2 px-2">
              <div className="flex items-center space-x-2 snap-x snap-mandatory">
                {stats?.examList.map((exam, index) => (
                  <motion.div
                    key={index}
                    layout
                    className={`flex items-center px-6 py-3 rounded-full cursor-pointer transition-all duration-300 snap-start ${
                      selectedTest === exam 
                        ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg' 
                        : 'bg-white/90 backdrop-blur-md hover:bg-white/95 text-gray-700 hover:shadow-md'
                    }`}
                    onClick={() => setSelectedTest(exam)}
                  >
                    <div className="flex items-center">
                      <span className="whitespace-nowrap">
                        {exam.metadata?.identifier?.displayName || exam.name}
                      </span>
                      {exam.metadata?.identifier?.certificationCode && (
                        <span className={`ml-2 text-xs px-2 py-0.5 rounded-full ${
                          selectedTest === exam 
                            ? 'bg-white/20 text-white' 
                            : 'bg-blue-100 text-blue-700'
                        }`}>
                          {exam.metadata.identifier.certificationCode}
                        </span>
                      )}
                    </div>
                    {exam.lastScore && (
                      <span className={`ml-3 px-2 py-1 rounded-full text-sm ${
                        selectedTest === exam 
                          ? 'bg-white/20' 
                          : 'bg-gradient-to-r from-blue-500 to-purple-600 text-white'
                      }`}>
                        {exam.lastScore.toFixed(0)}%
                      </span>
                    )}
                    <button
                      onClick={(e) => handleDeleteClick(e, exam)}
                      className={`ml-2 p-1 rounded-full hover:bg-red-500/10 transition-colors ${
                        selectedTest === exam ? 'text-white/80 hover:text-white' : 'text-gray-400 hover:text-red-500'
                      }`}
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </motion.div>
                ))}
              </div>
            </div>
  
            {/* Fade indicators for scroll */}
            <div className="absolute left-0 top-0 bottom-4 w-8 bg-gradient-to-r from-white/50 to-transparent pointer-events-none" />
            <div className="absolute right-0 top-0 bottom-4 w-8 bg-gradient-to-l from-white/50 to-transparent pointer-events-none" />
          </div>
  
          {/* Delete Confirmation Dialog */}
          <AlertDialog 
            isOpen={showDeleteDialog} 
            onClose={() => {
              setShowDeleteDialog(false);
              setExamToDelete(null);
            }}
          >
            <AlertDialogHeader>
              Delete {examToDelete?.metadata?.identifier?.displayName || examToDelete?.name}?
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this exam? This will permanently remove the exam and all {examToDelete?.attempts?.length || 0} attempts. This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => {
                setShowDeleteDialog(false);
                setExamToDelete(null);
              }}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={handleConfirmDelete}
                className="bg-red-500 hover:bg-red-600"
              >
                Delete Exam
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialog>
  
          {/* Selected Test Details */}
          <AnimatePresence mode="wait">
            {selectedTest && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-white/90 backdrop-blur-md rounded-3xl p-8 shadow-lg"
              >
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center">
                    <h3 className="text-2xl font-bold text-gray-800">
                      {selectedTest.metadata?.identifier?.displayName || selectedTest.name}
                    </h3>
                    {selectedTest.metadata?.identifier?.certificationCode && (
                      <span className="ml-3 text-sm bg-blue-100 text-blue-700 px-3 py-1 rounded-full">
                        {selectedTest.metadata.identifier.certificationCode}
                      </span>
                    )}
                  </div>
                  <Button
                    onClick={() => handleRetakeExam(selectedTest)}
                    className="bg-gradient-to-r from-green-500 to-emerald-600 hover:from-green-600 hover:to-emerald-700 text-white rounded-full px-6 flex items-center"
                  >
                    <span className="mr-2">Start New Attempt</span>
                  </Button>
                </div>
  
                <div className="space-y-4">
                  {selectedTest.attempts?.map((attempt, index) => (
                    <div 
                      key={index} 
                      className="flex items-center justify-between py-4 border-b border-gray-100 last:border-0"
                    >
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900">
                          Attempt {selectedTest.attempts.length - index}
                        </h3>
                        <p className="text-gray-600">
                          {formatDate(attempt.date) || 'Recent'}
                        </p>
                      </div>
                      <div className="flex items-center gap-4">
                        {attempt.score !== null && (
                          <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-1.5 rounded-full font-medium">
                            {attempt.score.toFixed(0)}%
                          </div>
                        )}
                        <button 
                          onClick={() => handleViewAttempt(selectedTest, attempt)}
                          className="text-gray-600 hover:text-indigo-600 font-medium transition-colors"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Account;
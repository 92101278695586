import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader } from './ui/card';
import { Button } from './ui/button';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { ExternalLink, AlertCircle, CheckSquare, XSquare, Loader2 } from 'lucide-react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction
} from './AlertDialog';
import QuestionFeedback from './QuestionFeedback';

const Switch = ({ id, checked, onCheckedChange }) => {
  return (
    <label htmlFor={id} className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          id={id}
          className="sr-only"
          checked={checked}
          onChange={onCheckedChange}
        />
        <div className={`block w-14 h-8 rounded-full ${checked ? 'bg-blue-500' : 'bg-gray-300'}`}></div>
        <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${checked ? 'transform translate-x-6' : ''}`}></div>
      </div>
    </label>
  );
};

const BatchLoadingIndicator = ({ current, total }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
      <CardContent className="p-6">
        <div className="text-center">
          <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4 text-purple-600" />
          <h3 className="text-lg font-semibold mb-2">Loading more questions...</h3>
          <p className="text-sm text-gray-600 mb-4">Please wait while we prepare the next set of questions.</p>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-purple-600 h-2 rounded-full transition-all duration-300" 
              style={{ width: `${(current/total) * 100}%` }}
            />
          </div>
          <p className="text-sm text-gray-600 mt-2">
            Batch {current} of {total}
          </p>
        </div>
      </CardContent>
    </Card>
  </div>
);

const PracticeTestPlayground = () => {
  const navigate = useNavigate();
  const { user, updateExamScore } = useContext(UserContext);
  const [exam, setExam] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [practiceMode, setPracticeMode] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [error, setError] = useState('');
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [isLoadingMoreQuestions, setIsLoadingMoreQuestions] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({ current: 1, total: 1 });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadExam = () => {
      const storedExamData = localStorage.getItem('currentExam');
      if (!storedExamData) {
        navigate('/', { state: { message: "No active exam found. Please generate a new exam." } });
        return;
      }
  
      try {
        const { exam: parsedExam, startTime: storedStartTime } = JSON.parse(storedExamData);
        
        if (!parsedExam || !parsedExam.content || !parsedExam.content.questions) {
          throw new Error('Invalid exam data');
        }
  
        // Initialize exam state
        setExam(parsedExam);
        setStartTime(storedStartTime || Date.now()); // Use stored start time or current time
        setUserAnswers({});  // Reset answers
        setCurrentQuestionIndex(0);  // Reset question index
  
        // Handle any cleanup or unsubscribe if needed
        return () => {
          // If user hasn't submitted answers, clean up localStorage
          if (Object.keys(userAnswers).length === 0) {
            localStorage.removeItem('currentExam');
          }
        };
      } catch (error) {
        console.error('Error loading exam:', error);
        localStorage.removeItem('currentExam'); // Clean up invalid data
        navigate('/', { state: { message: "Error loading exam. Please try again." } });
      }
    };
  
    loadExam();
  }, [navigate]);

  const handleAnswer = (answer) => {
    setUserAnswers(prev => {
      const currentAnswers = prev[currentQuestionIndex] || [];
      const isMultipleChoice = exam.content.questions[currentQuestionIndex].answers.length > 1;
      
      let newAnswers;
      if (isMultipleChoice) {
        if (currentAnswers.includes(answer)) {
          newAnswers = currentAnswers.filter(a => a !== answer);
        } else {
          newAnswers = [...currentAnswers, answer];
        }
      } else {
        newAnswers = [answer];
      }
  
      const updatedAnswers = {
        ...prev,
        [currentQuestionIndex]: newAnswers
      };
  
      if (practiceMode) {
        const correctAnswersCount = exam.content.questions[currentQuestionIndex].answers.length;
        setShowFeedback(newAnswers.length === correctAnswersCount);
      }
  
      return updatedAnswers;
    });
    setError('');
  };

  const handleNext = () => {
    const currentAnswers = userAnswers[currentQuestionIndex] || [];
    const currentQuestion = exam?.content?.questions?.[currentQuestionIndex];
    
    // Get the actual required number of answers from the current question
    const requiredAnswers = currentQuestion?.answers?.length || 1;
  
    if (currentAnswers.length !== requiredAnswers) {
      setError(`Please select ${requiredAnswers} answer${requiredAnswers > 1 ? 's' : ''} before proceeding.`);
      return;
    }
  
    setError('');
    setShowFeedback(false);
  
    if (currentQuestionIndex + 1 < (exam?.content?.questions?.length || 0)) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleFinish();
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShowFeedback(false);
      setError('');
    }
  };

  const calculateScore = () => {
    if (!exam || !exam.content || !exam.content.questions) return 0;
  
    let score = 0;
    exam.content.questions.forEach((question, index) => {
      const userAnswer = userAnswers[index] || [];
      const correctAnswers = question.answers;
      if (JSON.stringify(userAnswer.sort()) === JSON.stringify(correctAnswers.sort())) {
        score++;
      }
    });
    return (score / exam.content.questions.length) * 100;
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleFinish = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      // Validate all questions are answered
      const totalQuestions = exam?.content?.questions?.length || 0;
      const answeredQuestions = Object.keys(userAnswers).length;
      
      if (answeredQuestions !== totalQuestions) {
        setError(`Please answer all questions before finishing. ${answeredQuestions}/${totalQuestions} questions answered.`);
        setIsLoading(false);
        return;
      }
  
      const endTime = Date.now();
      const timeTaken = Math.floor((endTime - startTime) / 1000);
      const score = calculateScore();
      const timestamp = Date.now();
  
      // Create attempt object
      const completedAttempt = {
        id: `${Date.now()}`, // Ensure unique ID
        score: score,
        answers: Object.entries(userAnswers).map(([index, answers]) => 
          Array.isArray(answers) ? answers : [answers]
        ),
        completed: true,
        timeTaken: formatTime(timeTaken),
        date: new Date().toISOString().split('T')[0],
        questionResponses: exam.content.questions.map((question, index) => {
          const userAnswer = userAnswers[index] || [];
          const normalizedUserAnswer = Array.isArray(userAnswer) ? userAnswer : [userAnswer];
          const normalizedCorrectAnswer = Array.isArray(question.answers) ? 
            question.answers : [question.answers];
  
          return {
            questionId: question.id,
            userAnswers: normalizedUserAnswer,
            correct: JSON.stringify(normalizedUserAnswer.sort()) === 
                    JSON.stringify(normalizedCorrectAnswer.sort())
          };
        })
      };
  
      // Create the updated exam object
      const updatedExam = {
        ...exam,
        content: {
          ...exam.content,
          questions: exam.content.questions,
          status: 'complete',
          completedBatches: exam.content.totalBatches || 1,
          totalBatches: exam.content.totalBatches || 1,
          totalQuestions: exam.content.questions.length
        },
        lastScore: score,
        date: new Date().toISOString()
      };
  
      // Store result for ExamResultScreen
      const examResult = {
        exam: updatedExam,
        attempt: completedAttempt
      };
      
      localStorage.setItem('currentExamResult', JSON.stringify(examResult));
  
      // Update in UserContext and wait for it to complete
      await updateExamScore(exam.name, completedAttempt, updatedExam);
  
      // Clean up
      localStorage.removeItem('currentExam');
      setIsLoading(false);
  
      // Navigate to results with the correct attempt ID
      navigate(`/exam-result/${encodeURIComponent(exam.name)}/${completedAttempt.id}`);
    } catch (error) {
      console.error('Error finishing exam:', error);
      setError('Failed to save exam results. Please try again.');
      setIsLoading(false);
    }
  };

  const handleExitConfirm = () => {
    localStorage.removeItem('currentExam');
    navigate('/account');
  };

  const handleExitCancel = () => {
    setShowExitDialog(false);
  };

  const isAnswerCorrect = (option) => {
    const correctAnswers = exam.content.questions[currentQuestionIndex].answers;
    return correctAnswers.includes(option);
  };

  const isAllCorrect = () => {
    const userAnswer = userAnswers[currentQuestionIndex] || [];
    const correctAnswers = exam.content.questions[currentQuestionIndex].answers;
    return userAnswer.length === correctAnswers.length && 
           userAnswer.every(answer => correctAnswers.includes(answer));
  };

  if (!exam) return null;

  const currentQuestion = exam?.content?.questions?.[currentQuestionIndex] || {};
  const totalQuestions = exam?.content?.questions?.length || 0;
  const isLastQuestion = currentQuestionIndex + 1 === totalQuestions;
  const isMultipleChoice = currentQuestion.answers.length > 1;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 p-4 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-2xl"
      >
        <Card className="bg-white shadow-lg rounded-lg">
          <CardHeader className="flex flex-col relative">
            <div className="absolute right-2 top-2">
              <QuestionFeedback questionId={`${exam.name}-${currentQuestionIndex}`} />
            </div>
            <div className="flex flex-col items-center gap-2">
              <h2 className="text-xl font-semibold">
                Question {currentQuestionIndex + 1} of {totalQuestions}
              </h2>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Practice Mode</span>
                <Switch
                  id="practice-mode"
                  checked={practiceMode}
                  onCheckedChange={() => {
                    setPracticeMode(!practiceMode);
                    setShowFeedback(false);
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <p className="text-lg mb-4">{currentQuestion.question}</p>
            <p className="text-sm text-gray-600 mb-2">
              Select {currentQuestion?.answers?.length || 1} answer{currentQuestion?.answers?.length > 1 ? 's' : ''}.
            </p>
            <div className="space-y-2">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="flex items-center space-x-2 cursor-pointer p-2 rounded hover:bg-gray-50">
                  <input
                    type={isMultipleChoice ? "checkbox" : "radio"}
                    name={`question-${currentQuestionIndex}`}
                    value={option}
                    checked={(userAnswers[currentQuestionIndex] || []).includes(option)}
                    onChange={() => handleAnswer(option)}
                    className={`form-${isMultipleChoice ? 'checkbox' : 'radio'} h-4 w-4 text-blue-600`}
                  />
                  <span className="text-md">{option}</span>
                  {practiceMode && showFeedback && (
                    isAnswerCorrect(option) ? (
                      <CheckSquare className="h-5 w-5 text-green-500 ml-auto" />
                    ) : (
                      (userAnswers[currentQuestionIndex] || []).includes(option) && (
                        <XSquare className="h-5 w-5 text-red-500 ml-auto" />
                      )
                    )
                  )}
                </label>
              ))}
            </div>
            {error && (
              <div className="mt-4 p-2 bg-red-100 text-red-700 rounded-md flex items-center">
                <AlertCircle className="h-5 w-5 mr-2" />
                <span>{error}</span>
              </div>
            )}
            {practiceMode && showFeedback && (
              <div className="mt-4 p-4 rounded-lg bg-blue-100">
                <p className="font-bold text-blue-700">
                  {isAllCorrect() ? "Correct!" : "Incorrect."}
                </p>
                <p className="text-gray-700 mt-2">{currentQuestion.explanation}</p>
                {currentQuestion.reference && (
                  <a
                    href={currentQuestion.reference}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                  >
                    <ExternalLink size={16} className="mr-1" />
                    Learn more
                  </a>
                )}
              </div>
            )}
            <div className="flex justify-between mt-6">
              <Button 
                onClick={handlePrevious} 
                disabled={currentQuestionIndex === 0} 
                variant="outline"
              >
                Previous
              </Button>
              <Button 
                onClick={() => setShowExitDialog(true)} 
                variant="outline"
              >
                Exit
              </Button>
              <Button 
                onClick={isLastQuestion ? handleFinish : handleNext} 
                className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300"
                disabled={!userAnswers[currentQuestionIndex] || (practiceMode && !showFeedback)}
              >
                {isLastQuestion ? 'Finish' : 'Next'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </motion.div>

      {/* Loading indicator */}
      {isLoadingMoreQuestions && currentQuestionIndex >= exam.content.questions.length - 1 && (
        <BatchLoadingIndicator 
          current={loadingProgress.current}
          total={loadingProgress.total}
        />
      )}

      {/* Alert Dialog */}
      <AlertDialog isOpen={showExitDialog} onClose={() => setShowExitDialog(false)}>
        <AlertDialogHeader>Are you sure you want to exit?</AlertDialogHeader>
        <AlertDialogBody>
          Your progress will not be saved if you exit now. This action cannot be undone.
        </AlertDialogBody>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleExitCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleExitConfirm}>Exit Exam</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialog>
    </div>
  );
};

export default PracticeTestPlayground;
import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Loader2, Clock, Sparkles } from 'lucide-react';

const ExamGenerationProgress = ({ isLoading, onComplete, metadata, stage, isExisting }) => {
  const [progress, setProgress] = useState(0);
  const [startTime] = useState(Date.now());

  // Simplified stage configs
  const stageConfigs = {
    cached: {
      ANALYZING: {
        message: "Analyzing topic and validating metadata...",
        duration: 2000,
        target: 33
      },
      PROCESSING: {
        message: "Processing verified question bank...",
        duration: 2000,
        target: 66
      },
      FINALIZING: {
        message: "Preparing exam content...",
        duration: 2000,
        target: 100
      }
    },
    new: {
      ANALYZING: {
        message: "Analyzing topic and generating metadata...",
        duration: 3000,
        target: 25
      },
      GENERATING: {
        message: "Generating practice questions...",
        duration: 15000,
        target: 60
      },
      PROCESSING: {
        message: "Processing certification details...",
        duration: 5000,
        target: 85
      },
      FINALIZING: {
        message: "Finalizing exam content...",
        duration: 3000,
        target: 100
      }
    }
  };


  const calculateProgress = useCallback(() => {
    const configs = isExisting ? stageConfigs.cached : stageConfigs.new;
    const currentConfig = configs[stage];
    
    if (!currentConfig) return progress;

    const elapsed = Date.now() - startTime;
    const targetForStage = currentConfig.target;
    const previousTarget = getPreviousStageTarget(stage, configs);
    
    const progressInStage = Math.min(100, (elapsed / currentConfig.duration) * 100);
    const rangeSize = targetForStage - previousTarget;
    
    return Math.min(targetForStage, previousTarget + (rangeSize * progressInStage / 100));
  }, [startTime, stage, isExisting, progress]);

  const getPreviousStageTarget = (currentStage, configs) => {
    const stages = Object.keys(configs);
    const currentIndex = stages.indexOf(currentStage);
    if (currentIndex <= 0) return 0;
    return configs[stages[currentIndex - 1]].target;
  };

  useEffect(() => {
    let progressInterval;
    let completionTimeout;

    if (isLoading) {
      progressInterval = setInterval(() => {
        const newProgress = calculateProgress();
        setProgress(newProgress);
        
        if (stage === 'FINALIZING' && newProgress >= 99.5) {
          clearInterval(progressInterval);
          setProgress(100);
          completionTimeout = setTimeout(() => {
            onComplete?.();
          }, 500);
        }
      }, 50);
    }

    return () => {
      if (progressInterval) clearInterval(progressInterval);
      if (completionTimeout) clearTimeout(completionTimeout);
    };
  }, [isLoading, calculateProgress, stage, onComplete]);

  return (
    <div className="mt-6 space-y-6">
      <div className="flex items-center gap-2 mb-2">
        <Loader2 className="w-4 h-4 text-blue-500 animate-spin" />
        <span className="text-sm text-gray-600">
          {(isExisting ? stageConfigs.cached : stageConfigs.new)[stage]?.message || "Processing..."}
        </span>
      </div>
      
      <div className="relative h-2 bg-gray-100 rounded-full overflow-hidden">
        <motion.div
          className="absolute top-0 left-0 h-full bg-blue-500"
          initial={{ width: "0%" }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>
      
      <div className="mt-2 flex items-center text-xs text-gray-500">
        <div className="w-1.5 h-1.5 bg-blue-500 rounded-full animate-pulse mr-2" />
        <span>Processing {Math.round(progress)}%</span>
      </div>

  
      {/* Metadata Card */}
      {metadata && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-blue-50 rounded-lg p-4 mt-4"
        >
          {/* Header with Exam Info */}
          <div className="flex items-center justify-between mb-3">
            <h3 className="font-semibold text-blue-900">
              {metadata.identifier.displayName}
            </h3>
            {isExisting && (
              <span className="text-sm bg-emerald-100 text-emerald-700 px-3 py-1 rounded-full flex items-center">
                <span className="mr-1">●</span> Using Verified Test Bank
              </span>
            )}
          </div>
  
          {/* Certification Details Grid */}
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div className="space-y-1">
              <span className="text-gray-600">Certification:</span>
              <p className="font-medium flex items-center">
                {metadata.certification?.vendor}
                {metadata.certification?.certificationCode && (
                  <span className="ml-2 text-xs bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
                    {metadata.certification.certificationCode}
                  </span>
                )}
              </p>
            </div>
            <div className="space-y-1">
              <span className="text-gray-600">Level:</span>
              <p className="font-medium">{metadata.certification?.level}</p>
            </div>
            <div className="space-y-1">
              <span className="text-gray-600">Estimated Study Hours:</span>
              <p className="font-medium">{metadata.content?.estimatedStudyHours}h</p>
            </div>
            <div className="space-y-1">
              <span className="text-gray-600">Difficulty:</span>
              <p className="font-medium capitalize">
                {metadata.content?.difficulty}
              </p>
            </div>
          </div>

        </motion.div>
      )}
    </div>
  );
};

export default ExamGenerationProgress;
import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { analytics } from './firebaseConfig';
import { setAnalyticsCollectionEnabled } from "firebase/analytics";
import { UserProvider, UserContext } from './components/UserContext';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuLink,
} from "./components/ui/navigation-menu.tsx";
import { Button } from './components/ui/button';

// Import all your components
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import LLMInteraction from './components/LLMInteraction';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Pricing from './components/Pricing';
import Checkout from './components/Checkout';
import FeedbackPage from './components/Feedback';
import Account from './components/Account';
import ResetPassword from './components/ResetPassword';
import PracticeTestPlayground from './components/PracticeTestPlayground';
import ExamResultScreen from './components/ExamResultScreen';
import AttemptReview from './components/AttemptReview';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsOfUsePage from './components/TermsOfUsePage';
import CookiePolicyPage from './components/CookiePolicyPage';
import CookieConsentBanner from './components/CookieConsentBanner';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Moved Header to a separate component with useNavigate hook
const HeaderWithNavigation = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <nav className="absolute top-8 left-0 right-0 z-50">
      <div className="relative max-w-7xl mx-auto">
        {/* Absolute positioned login/account button */}
        <div className="absolute right-8 top-1/2 -translate-y-1/2">
          <Link
            to={user ? "/account" : "/login"}
            className="px-4 py-2 rounded-full text-sm font-semibold bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:opacity-90 transition-all hover:scale-105"
          >
            {user ? "Account" : "Login"}
          </Link>
        </div>

        {/* Centered navigation */}
        <div className="max-w-2xl mx-auto">
          <div className="flex justify-center gap-20">
            <Link 
              to="/Pricing" 
              className="text-base font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all hover:scale-105"
            >
              Pricing
            </Link>
            <Link 
              to="/" 
              className="text-base font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all hover:scale-105"
            >
              Practice
            </Link>
            {/* <Link 
              to="/pricing" 
              className="text-base font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all hover:scale-105"
            >
              Pricing
            </Link> */}
            <Link 
              to="/Feedback" 
              className="text-base font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all hover:scale-105"
            >
              Feedback
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

function AppContent() {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  const handleConsent = (consent) => {
    setAnalyticsEnabled(consent);
    setAnalyticsCollectionEnabled(analytics, consent);
  };

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsentGiven');
    if (consentGiven === 'true') {
      handleConsent(true);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-purple-50">
      <HeaderWithNavigation />
      <Routes>
        <Route path="/" element={<LLMInteraction />} />
        <Route path="/about" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/Feedback" element={<FeedbackPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/account" element={<Account />} />
        <Route path="/attempt-review/:examId/:attemptId" element={<AttemptReview />} />
        <Route path="/exam-review/:examId/:attemptId" element={<AttemptReview />} />
        <Route path="/practice-test" element={<PracticeTestPlayground />} />
        <Route path="/exam-result/:examId/:attemptId" element={<ExamResultScreen />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfUsePage />} />
        <Route path="/cookies" element={<CookiePolicyPage />} />
      </Routes>
      <Footer />
      <CookieConsentBanner onConsent={handleConsent} />
    </div>
  );
}

function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <UserProvider>
        <Router>
          <AppContent />
        </Router>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { AlertCircle, Loader2, CheckCircle } from 'lucide-react';
import { Alert, AlertDescription } from './ui/CustomAlert';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const navigate = useNavigate();
  const { signup } = useContext(UserContext);

  const getUnmetPasswordCriteria = (password) => {
    const criteria = [];
    if (password.length < 8) criteria.push('At least 8 characters');
    if (!/[A-Z]/.test(password)) criteria.push('One uppercase letter');
    if (!/[a-z]/.test(password)) criteria.push('One lowercase letter');
    if (!/[0-9]/.test(password)) criteria.push('One number');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) criteria.push('One special character');
    return criteria;
  };

  const unmetCriteria = getUnmetPasswordCriteria(password);
  const isPasswordStrong = unmetCriteria.length === 0;

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setError('');

    if (!agreedToTerms) {
      setError("Please agree to the Terms and Conditions to continue.");
      setLoading(false);
      return;
    }

    if (!isPasswordStrong) {
      setError("Please ensure your password meets all the strength requirements.");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setLoading(false);
      return;
    }

    try {
      await signup(email, password, name);
      setVerificationSent(true);
      navigate('/login', { 
        state: { 
          message: 'Account created successfully! Please check your email to verify your account.',
          previousPath: localStorage.getItem('redirectPath') || '/'
        }
      });
    } catch (error) {
      console.error('Sign Up Error:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Please enter a valid email address.');
      } else if (error.code === 'auth/weak-password') {
        setError('Password is too weak. Please follow the password requirements.');
      } else {
        setError('Failed to create account. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  if (verificationSent) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex flex-col items-center justify-center p-4">
        <Card className="w-full max-w-lg bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
          <CardContent className="p-8">
            <div className="text-center">
              <CheckCircle className="mx-auto h-12 w-12 text-green-500 mb-4" />
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Verify Your Email</h2>
              <p className="text-gray-600 mb-6">
                A verification email has been sent to <span className="font-semibold">{email}</span>. 
                Please check your inbox and click on the verification link to complete your registration.
              </p>
              <Button 
                onClick={() => navigate('/login')} 
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 rounded-lg transition-all duration-300"
              >
                Go to Login
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex flex-col items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-lg"
      >
        <Card className="w-full bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
          <CardContent className="p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
              Create an Account
            </h2>

            {error && (
              <Alert variant="error" className="mb-6">
                <AlertCircle className="h-4 w-4 text-red-600 mr-2" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <form onSubmit={handleSignUp} className="space-y-6">
              <div>
                <Input 
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded-lg"
                  required
                  disabled={loading}
                />
              </div>

              <div>
                <Input 
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full rounded-lg"
                  required
                  disabled={loading}
                />
              </div>

              <div>
                <Input 
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setPasswordFocused(true)}
                  className="w-full rounded-lg"
                  required
                  disabled={loading}
                />
              </div>

              {passwordFocused && unmetCriteria.length > 0 && (
                <div className="space-y-2 p-3 bg-gray-50 rounded-lg">
                  <p className="text-sm font-semibold text-gray-700">Password must contain:</p>
                  {unmetCriteria.map((criterion) => (
                    <div key={criterion} className="flex items-center space-x-2">
                      <AlertCircle className="text-red-500" size={16} />
                      <span className="text-sm text-red-500">{criterion}</span>
                    </div>
                  ))}
                </div>
              )}

              <div>
                <Input 
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordTouched(true);
                  }}
                  className="w-full rounded-lg"
                  required
                  disabled={loading}
                />
              </div>

              {confirmPasswordTouched && password !== confirmPassword && (
                <p className="text-red-500 text-sm">Passwords don't match</p>
              )}

              <div className="flex items-start space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  className="mt-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500 h-4 w-4"
                  disabled={loading}
                />
                <label htmlFor="terms" className="text-sm text-gray-600">
                  I agree to the{' '}
                  <button
                    type="button"
                    onClick={() => window.open('/terms', '_blank')}
                    className="text-blue-600 hover:underline"
                  >
                    Terms and Conditions
                  </button>
                  {' '}and{' '}
                  <button
                    type="button"
                    onClick={() => window.open('/privacy', '_blank')}
                    className="text-blue-600 hover:underline"
                  >
                    Privacy Policy
                  </button>
                </label>
              </div>

              <Button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 rounded-lg transition-all duration-300 flex items-center justify-center"
                disabled={loading || !isPasswordStrong || password !== confirmPassword || !agreedToTerms}
              >
                {loading ? (
                  <>
                    <Loader2 className="animate-spin mr-2" size={20} />
                    Creating Account...
                  </>
                ) : (
                  'Create Account'
                )}
              </Button>
            </form>

            <div className="mt-6 text-center">
              <span className="text-sm text-gray-600">Already have an account? </span>
              <button 
                onClick={() => navigate('/login')}
                className="text-sm text-blue-600 hover:text-blue-800 transition-colors font-semibold"
                disabled={loading}
              >
                Log In
              </button>
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default SignUp;